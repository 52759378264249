import {Component, Vue, Watch} from 'vue-property-decorator';
import AuthController from '@/controllers/AuthController';
import Settings from '@/config/Settings';
import ResultDialogContent from '@/models/ResultDialogContent';
import EditionController from "@/controllers/EditionController";
import UserInfo from "@/models/UserInfo";

@Component
export default class AnnouncementView extends Vue {
    public createBackgroundString() {
        return `linear-gradient(145deg, #003366 25%, #FF3333 75%)`;
    }

    public showLogin: boolean = this.$route.query.showLogin === 'true';
    public loggedIn: boolean = false;
    public userName: string = '';
    public editions: string[] = new Array<string>();
    public edition: string = '';

    public mounted() {
        this.$nextTick(async () => {
            this.loggedIn = await AuthController.loggedIn();
            this.showLogin = true;
            if (this.loggedIn) {
                this.editions = await EditionController.getEditions();
                this.editions.sort(
                    (a, b) =>
                        (a > b) ? -1 : ((b > a) ? 1 : 0));
                const rawUserInfo = await AuthController.userInfo();
                this.$root.$data.userInfo = new UserInfo(rawUserInfo.email, rawUserInfo.name);
                this.userName = this.$root.$data.userInfo.userName;
                this.edition = await EditionController.getActiveEdition();
                if (this.$route.name === 'Home' && this.edition !== '') {
                    await this.$router.push({name: this.edition + '_1'});
                }
            } else {
                if (this.$route.name === 'SendInvite') {
                    return;
                }
                this.logout();
            }
        });
    }

    public get editionList(): string[] {
        return this.editions;
    }

    public get loginUrl(): string {
        return `${Settings.authUrl}/login?state=` + this.$route.path;
    }

    @Watch('edition')
    public async setActiveEdition() {
        if (this.loggedIn && this.edition !== await EditionController.getActiveEdition()) {
            await EditionController.setActiveEdition(this.edition);
            await this.$router.push({name: this.edition + '_1'});
        }
    }

    public logout(): void {
        AuthController.logout().then(async () => {
            this.loggedIn = await AuthController.loggedIn();
            this.$root.$data.userInfo = new UserInfo();
            this.userName = '';
            this.edition = '';
            await this.$router.push({name: 'Home'}).catch((err) => {
                if (
                    err.name !== 'NavigationDuplicated' &&
                    !err.message.includes('Avoided redundant navigation to current location')
                ) {
                    throw err;
                }
            });
        });
    }

    public showDialog(successMessage: string) {
        if (this.$root.$data.riddleResult.success) {
            this.$root.$data.nextPuzzle = true;
            this.$root.$data.resultContent = new ResultDialogContent(
                'Goed gedaan!',
                `${successMessage}<br /><br />${this.$root.$data.riddleResult.hintForNextPuzzle}`
            );
        } else {
            this.$root.$data.nextPuzzle = false;
            this.$root.$data.resultContent = new ResultDialogContent(
                'Helaas!',
                `${this.$root.$data.riddleResult.hintForNextPuzzle}`
            );
        }
        this.$root.$data.showDialog = true;
    }

    public async goToNextPuzzle(route: string) {
        this.$root.$data.showDialog = false;
        if (!this.loggedIn) {
            await this.$router.push({name: 'Home'});
        } else {
            if (this.$root.$data.nextPuzzle) {
                const splittedRoute = route.split('_');
                await this.$router.push(
                    {
                        name: splittedRoute[0] + '_' + splittedRoute[1] + '_' + (+splittedRoute[2] + 1),
                        query: {hint: this.$root.$data.riddleResult.hintForNextPuzzle}
                    }
                );
            }
            this.$root.$data.nextPuzzle = false;
        }
    }

    public getRoute(puzzleNumber: number) {
        if (this.edition === '') {
            return {name: 'Home'};
        }
        const name = this.edition + '_' + puzzleNumber;
        return {name};
    }
}
