import { Component } from 'vue-property-decorator';
import RiddleController from '@/controllers/RiddleController';
import AnnouncementView from '@/views/AnnouncementView/AnnouncementView';
import Riddle from '@/models/Riddle';

@Component
export default class Matrix extends AnnouncementView {
  public assignment: string = '';
  public matrix: string = '';

  public created() {
    this.$nextTick(async () => {
      if (this.$route.name != null) {
        const riddle: Riddle = await RiddleController.getRiddle(this.$route.name);
        this.assignment = riddle.assignment;
        this.matrix = riddle.riddle;
      }
    });
  }
}
