import Vue from 'vue';
import router from './router';
import vuetify from './plugins/vuetify';
import AnnouncementView from './views/AnnouncementView/AnnouncementView.vue';
import ResultDialogContent from '@/models/ResultDialogContent';
import RiddleResult from '@/models/RiddleResult';
import UserInfo from "@/models/UserInfo";

Vue.config.productionTip = false;

export const app = new Vue({
    data: {
        showDialog: false,
        riddleResult: new RiddleResult(),
        resultContent: new ResultDialogContent(),
        nextPuzzle: false,
        userInfo: new UserInfo()
    },
    router,
    vuetify,
    render: (h) => h(AnnouncementView)
});
app.$mount('#app');
