import axios from 'axios';
import Settings from '@/config/Settings';

axios.defaults.withCredentials = true;
axios.defaults.headers.common = {'Content-Type': 'application/json'};

export default class EditionController {

    public static async getEditions() {
        return axios.get(`${Settings.editionsUrl}`).then((response) => {
            if (response.status.valueOf() === 200) {
                return response.data as string[];
            }
            return [];
        });
    }

    static async getActiveEdition() {
        return axios.get(`${Settings.editionsUrl}/active`).then((response) => {
            if (response.status.valueOf() === 200) {
                return response.data as string;
            }
            return '';
        });
    }

    static async setActiveEdition(edition: string) {
        await axios.post(`${Settings.editionsUrl}/active?edition=${edition}`);
    }
}
