import {Component} from 'vue-property-decorator';
import AnnouncementView from '@/views/AnnouncementView/AnnouncementView';
import RiddleController from '@/controllers/RiddleController';

@Component
export default class GetTheDateTime extends AnnouncementView {
    public date = '';
    public time = '';

    public created() {
        this.$nextTick(async () => {
            if (this.$route.name != null) {
                await RiddleController.getRiddle(this.$route.name);
            }
        });
    }

    public async evaluate() {
        this.$root.$data.riddleResult = await RiddleController.evaluateRiddleAnswer(
            <string>this.$route.name,
            this.date + 'T' + this.time
        );
        if (this.$root.$data.riddleResult.success) {
            this.showDialog('Je weet nu wanneer de meeting is.');
        } else {
            this.showDialog('')
        }
    }
}
