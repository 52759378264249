import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';

import Filippine from '@/components/Filippine/Filippine.vue';
import GetTheDateTime from '@/components/GetTheDateTime/GetTheDateTime.vue';
import Rot13 from '@/components/Rot13/Rot13.vue';
import SendInvite from '@/components/SendInvite/SendInvite.vue';
import Matrix from '@/components/Matrix/Matrix.vue';
import Cryptex from '@/components/Cryptex/Cryptex.vue';
import Uno from "@/components/Uno/Uno.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/components/Home/Home.vue')
    },
    {
        path: '/2023/1/1',
        name: '2023_1_1',
        component: Filippine
    },
    {
        path: '/2023/1/2',
        name: '2023_1_2',
        component: GetTheDateTime
    },
    {
        path: '/2023/1/3',
        name: '2023_1_3',
        component: Rot13
    },
    {
        path: '/2023/2/1',
        name: '2023_2_1',
        component: Uno
    }, {
        path: '/2023/2/2',
        name: '2023_2_2',
        component: Cryptex
    },
    {
        path: '/2023/2/3',
        name: '2023_2_3',
        component: Matrix
    },
    {
        path: '**/sendinvite',
        name: 'SendInvite',
        component: SendInvite
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
