import axios from 'axios';
import Settings from '@/config/Settings';
import UserInfo from "@/models/UserInfo";

axios.defaults.withCredentials = true;
axios.defaults.headers.common = { 'Content-Type': 'application/json' };

export default class AuthController {
  public static async loggedIn(): Promise<boolean> {
    return await axios.get(`${Settings.authUrl}/loggedIn`).then((response) => {
      if (response.status.valueOf() === 200) {
        return response.data;
      }
      return false;
    });
  }

  public static async userInfo(): Promise<any> {
    return await axios.get(`${Settings.authUrl}/userinfo`).then((response) => {
      if (response.status.valueOf() === 200) {
        return response.data;
      }
      return new UserInfo();
    });
  }

  public static async logout(): Promise<void> {
    return await axios.get(`${Settings.authUrl}/logout`);
  }
}
