import RiddleSolution from '@/models/RiddleSolution';
import axios from 'axios';
import Settings from '@/config/Settings';
import RiddleResult from '@/models/RiddleResult';
import Riddle from '@/models/Riddle';

axios.defaults.withCredentials = true;
axios.defaults.headers.common = {'Content-Type': 'application/json'};

export default class RiddleController {
    public static async evaluateRiddleAnswer(riddlename: string, solution: string): Promise<RiddleResult> {
        const riddleSolution = new RiddleSolution(solution);
        const body = new FormData();
        body.append('json', JSON.stringify(riddleSolution));

        return await axios.post(`${Settings.riddleUrl}/answer?riddleName=${riddlename}`, body).then((response) => {
            if (response.status.valueOf() === 200) {
                return response.data;
            }
            return new RiddleResult(false, '');
        });
    }

    public static async getRiddle(name: string): Promise<Riddle> {
        return axios.get(`${Settings.riddleUrl}/riddle?name=${name}`).then((response) => {
            if (response.status.valueOf() === 200) {
                return response.data as Riddle;
            }
            return new Riddle();
        });
    }

    public static async getMeetingDate(r: string, e: string) {
        return axios.get(`${Settings.riddleUrl}/meetingDate?r=${r}&&e=${e}`).then((response) => {
            if (response.status.valueOf() === 200) {
                return response.data;
            }
            return '';
        });
    }
}
