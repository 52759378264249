import { Component } from 'vue-property-decorator';
import AnnouncementView from '@/views/AnnouncementView/AnnouncementView';
import RiddleController from '@/controllers/RiddleController';

@Component
export default class Rot13 extends AnnouncementView {
  public assignment: string = '';
  public code: string = '';

  public created() {
    this.$nextTick(async () => {
      if (this.$route.name != null) {
        const riddle = await RiddleController.getRiddle(this.$route.name);
        this.assignment = riddle.assignment;
        this.code = riddle.riddle;
      }
    });
  }
}
