import { Component } from 'vue-property-decorator';
import RiddleController from '@/controllers/RiddleController';
import AnnouncementView from '@/views/AnnouncementView/AnnouncementView';

@Component
export default class Filippine extends AnnouncementView {
  public answerArray = new Array<any>();
  public exercise = new Array<string>();
  public questions = new Array<string>();
  public update = false;

  public created() {
    this.$nextTick(async () => {
      if (this.$route.name != null) {
        const riddle = await RiddleController.getRiddle(this.$route.name);
        const assignment = riddle.assignment;
        const filippine = riddle.riddle;
        const splittedFilipine = filippine.split(', ');
        const splittedAssignment = assignment.split(', ');
        const splittedFilipineEntries = splittedFilipine.length;
        for (let index = 0; index < splittedFilipineEntries; index++) {
            this.exercise.push(splittedFilipine[index]);
            this.questions.push(splittedAssignment[index]);
        }
      }
      for (const answer of this.exercise) {
        const answerString = new Array<string>(12);
        this.answerArray.push(answerString);
      }
    });
  }

  public async evaluate() {
    this.$root.$data.riddleResult = await RiddleController.evaluateRiddleAnswer(
      <string>this.$route.name,
      this.formulateAnswer()
    );
    this.showDialog('Design is goed! Dat gaan we doen inderdaad, het gilde vormgeven.');
  }

  private formulateAnswer() {
    let answer = '';
    for (const word of this.answerArray) {
      for (const character of word) {
        if (character !== undefined) {
          answer += character;
        }
      }
      answer += ', ';
    }
    return answer.substring(0, answer.length - 2);
  }

  public focusNext(e: any) {
    if (e.key !== 'Tab' && e.key !== 'Shift') {
      const inputs = Array.from(window.document.querySelectorAll('input[type="text"]'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length - 1) {
        // @ts-ignore
        inputs[index + 1].focus();
      } else {
        // @ts-ignore
        window.document.querySelectorAll('button[id]')[0].focus();
      }
    }
  }
}
