import axios from 'axios';
import Settings from '@/config/Settings';

axios.defaults.withCredentials = true;
axios.defaults.headers.common = { 'Content-Type': 'application/json' };

export default class TeamsController {
  public static async sendInviteResponse(attend: boolean, riddleName: string, email: string): Promise<void> {
    const body = new FormData();
    body.append('join', JSON.stringify(attend));
    body.append('r', riddleName);
    body.append('e', email);
    return await axios.post(`${Settings.teamsUrl}`, body).then((response) => {
      if (response.status.valueOf() === 200) {
        return response.data.success;
      }
      return false;
    });
  }
}
