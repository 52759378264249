import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#003366',
        secondary: '#99cc00',
        accent: 'ff3333',
        error: '#b71c1c'
      },
      dark: {
        primary: '#99cc00',
        secondary: '#003366',
        accent: 'ff3333',
        error: '#b71c1c'
      }
    }
  }
});
