import {Component, Watch} from 'vue-property-decorator';
import AnnouncementView from '@/views/AnnouncementView/AnnouncementView';
import TeamsController from '@/controllers/TeamsController';
import Settings from '@/config/Settings';
import RiddleController from "@/controllers/RiddleController";
import DateHelper from "@/util/DateHelper";

@Component
export default class SendInvite extends AnnouncementView {
    public meetingDate: Date = new Date(1, 0, 1970);
    public email = '';
    public riddleName: string = '';
    public result = {success: false, hintForNextPuzzle: ''};

    public mounted() {
        this.updateEmail();
    }

    @Watch('$root.$data.userInfo.email')
    public updateEmail() {
        let date = '';
        this.riddleName = btoa('2023_1_3');
        if (this.$route.query.r) {
            this.riddleName = <string>this.$route.query.r;
        }
        let email = '';
        if (this.$route.query.e) {
            email = <string>this.$route.query.e;
            this.email = atob(email);
        } else {
            this.email = this.$root.$data.userInfo.email;
            email = btoa(this.email);
        }
        return this.$nextTick(async () => {
            this.result = await RiddleController.getMeetingDate(this.riddleName, email);
            if (this.result.success) {
                this.meetingDate = DateHelper.parseDate(this.result.hintForNextPuzzle);
            }
        });
    }

    public get formattedMeetingDate() {
        return DateHelper.formatDateVerbose(this.meetingDate);
    }

    public sendInvite(join: boolean) {
        TeamsController.sendInviteResponse(join, this.riddleName, btoa(this.email)).then(() => {
            if (join) {
                const fileUrl = Settings.uiUrl + '2023-2 tweede avond Development Gilde.ics';
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = 'development gilde 2023-2.ics';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        });
    }

    public activeMeeting(meetingDate: Date): boolean {
        if (meetingDate > new Date()) {
            return true;
        }
        return false;
    }
}
