export default class DateHelper {

    static parseDate(input: string): Date {
        const parts = input.split("/");

        if (parts.length !== 3) {
            return new Date(1,0,1970); // Invalid input format
        }

        const day = parseInt(parts[0]);
        const month = parseInt(parts[1]) - 1; // Months are zero-based in JavaScript Date
        const year = parseInt(parts[2]);

        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            return new Date(1,0,1970); // Invalid input format
        }

        const date = new Date(year, month, day);

        if (isNaN(date.getTime())) {
            return new Date(1,0,1970); // Invalid input format
        }
        return date;
    }

    static formatDateVerbose(date: Date): string {
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long', // Use 'long' for verbose month
            day: 'numeric',
        };

        return date.toLocaleString('nl-NL', options);
    }
}