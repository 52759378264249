import {Component} from 'vue-property-decorator';
import RiddleController from '@/controllers/RiddleController';
import AnnouncementView from '@/views/AnnouncementView/AnnouncementView';
import DateHelper from "@/util/DateHelper";

@Component
export default class Cryptex extends AnnouncementView {
  public exercise = '';
  public answer: number[] = new Array<number>()
  public update = false;
  public assignment:string = '';
  public alphabet: string[] =  [...'abcdefghijklmnopqrstuvwxyz'];

  public created() {
    this.$nextTick(async () => {
      if (this.$route.name != null) {
         const riddle = await RiddleController.getRiddle(this.$route.name);
        this.assignment = riddle.assignment;
        this.exercise = riddle.riddle;
        const splittedCryptex = this.exercise.split(' ');
        const splittedCryptexEntries = splittedCryptex.length;
        for (let index = 0; index < splittedCryptexEntries; index++) {
            this.answer.push(0);
        }
      }
    });
  }

  public async evaluate() {
    let stringAnswer = '';
    for (const index of this.answer) {
      stringAnswer += this.alphabet[index];
    }
    this.$root.$data.riddleResult = await RiddleController.evaluateRiddleAnswer(
      <string>this.$route.name,
      stringAnswer
    );
    this.showDialog('Goed gevonden, de afspraak is op');
  }


}
