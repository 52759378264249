import {Component, Watch} from 'vue-property-decorator';
import RiddleController from '@/controllers/RiddleController';
import AnnouncementView from '@/views/AnnouncementView/AnnouncementView';

@Component
export default class Uno extends AnnouncementView {
    public exercise = '';
    public answer: string[] = new Array<string>()
    public assignment: string = '';
    public cards: string[] = new Array<string>();

    public created() {
        this.$nextTick(async () => {
            if (this.$route.name != null) {
                const riddle = await RiddleController.getRiddle(this.$route.name);
                this.assignment = riddle.assignment;
                this.exercise = riddle.riddle.substring(1, riddle.riddle.length - 1);
                this.cards = this.exercise.split(', ');
            }
        });
    }

    public getSelected(card: string) {
        return this.answer.indexOf(card) > -1 ? 'border: 4px solid green; border-radius: 30px' : 'border: 1px solid black; border-radius: 30px';
    }

    public selectOrDeselectCard(card: string) {
        let cardIndex = this.answer.indexOf(card);
        cardIndex < 0 ? this.answer.push(card) : this.answer.splice(cardIndex, 1);
    }

    public async evaluate() {
        this.$root.$data.riddleResult = await RiddleController.evaluateRiddleAnswer(
            <string>this.$route.name,
            this.answer.join(', ')
        );
        this.showDialog('Je snapt de regels van UNO! We gaan het spel zelf bouwen op de gildeavond.');
    }


}
